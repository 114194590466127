
.page {
  display: flex;
}

.article-space {
  width: fit-content;
  flex: 1;
}

.suggestions {
  width: 20%;
  height: 100%;
  margin-right: 50px;
}

.image {
  max-width: 80%;
  max-height: 90vh;
  vertical-align: middle;
}

.title {
  width: 50%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.nav-button {
  width: 8%;
  min-width: 20px;
  min-height: 20px;
  max-width: 100px;
  max-height: 100px;
  display: inline-block;
  content:inherit;
  background-color: inherit;
  border: none;
}

.nav-button:hover {
  padding: 10px;
}

.nav-button:disabled .nav-icon {
  display: none;
}

.arrow-prev, .arrow-next {
  font-size: 50px;
  /* color: #340c0c; */
}

.nav-icon {
  width: 100%;
}