.footer--pin {
  border-top: none;
  padding: 5px;
}

.footer-nav {
  text-align: left;
}

.footer--pin li {
  list-style: none;
  padding: 5px;
}

.copyright {
  display: center;
  text-decoration: underline;
  font-family: "MinionPro";
}
