
.galleryElement {
  height: fit-content;
  margin: 10px;
}

.galleryElement img {
  max-height: 200px;
  cursor: pointer;

}

.galleryElement p {
  font-size: 14pt;
}

/* .galleryElement button {
  border-radius: 5px;
  background-color: #FFEDD2;
  border: none;
  padding: 15px 30px;
  font-family: "MinionPro";
  font-size: 1em;
}

.galleryElement button:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  background-color: #FFE6C0;
}

.galleryElement button:active {
  background-color: #FFE6C0;
  transform: translateY(4px);
} */