.app {
  font-family: "Adobe Garamond Pro Regular";
  font-size: 1.5em;
  text-align: center;
  /* color: #340c0c; */
  color: #880102;
  background-color: #fcf4c9;
  padding: 1px;
}

.content-container {
  min-height: calc(100vh - 140px);
}

h1, h2, h3 {
  color: #340c0c;
  font-weight: 100;
}

h1 {
  font-size: 70px;
  margin: 10px;
}

h2 {
  margin: 0;
  font-size: 40px;
}

h3 {
  font-size: 30px;
}